/* CLOCK FUNCTION */

function showTime() {
  var date = new Date();
  var h = date.getHours(); // 0 - 23
  var m = date.getMinutes(); // 0 - 59
  var s = date.getSeconds(); // 0 - 59

  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;

  var time = h + ":" + m + " ";
  document.getElementById("MyClockDisplay").innerText = time;
  document.getElementById("MyClockDisplay").textContent = time;
  coffeeAmount(h);
  setTimeout(showTime, 60000);
}

function coffeeAmount(hour) {
  let cups;
  let amount = document.querySelector(".amount");
  switch (true) {
    case hour < 8:
      cups = 0;
      break;
    case hour > 8 && hour < 10:
      cups = 2;
      break;
    case hour > 10 && hour < 12:
      cups = 3;
      break;
    case hour > 12 && hour < 14:
      cups = 4;
      break;
    case hour > 14:
      cups = 5;
      break;
  }
  amount.innerText = `${cups} cups today`;
}

showTime();
